import React from 'react';
import format from 'date-fns/format';

import { MONTH_DAY_YEAR_DATETIME_FORMAT } from '@/Framework/DateTime/dateFormats';
import { useScheduleDemoContext } from '@/ui/shared/modules/ScheduleDemo/ScheduleDemoContext';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import getDateFromUnixTime from '@/Framework/DateTime/getDateFromUnixTime';

import styles from './scheduleDemoFormSuccess.scss';

const ScheduleDemoFormSuccess = () => {
  const { submittedFormValues: {
    demoDateTime,
    firstName,
    lastName,
    corporateEmail,
    companyName,
    phone,
    timeZone,
  } } = useScheduleDemoContext();

  return (
    <div className={ styles.successContainer } data-test="successContainer">
      <h2 className={ styles.title }>Thank You!</h2>
      <div className={ styles.appointment }>
        <div className={ styles.appointmentContainer }>
          <div className={ styles.appointmentTitle }>Your appointment is scheduled for:</div>
          <div className={ styles.appointmentDate } data-test="date">
            {
              format(
                utcToZonedDate(
                  timeZone.timeZone,
                  getDateFromUnixTime(+demoDateTime),
                ),
                MONTH_DAY_YEAR_DATETIME_FORMAT,
              )
            } { timeZone.abbr }
          </div>
          <div className={ styles.divider } />
          <div className={ styles.contactDetails }>
            <div data-test="fullName">{ firstName } { lastName }</div>
            <div data-test="company">{ companyName }</div>
            <div data-test="email">{ corporateEmail }</div>
            <div data-test="phone">{ phone }</div>
          </div>
        </div>
      </div>
      <p className={ styles.description }>
        Our team will send a meeting planner within twenty minutes as confirmation.
      </p>
    </div>
  );
};

export default ScheduleDemoFormSuccess;
